<template>
  <div class="pure-search-bar">
    <div class="pure-search-bar-tool search-input" :style="customStyle">
      <img
        class="pure-search-bar-tool-icon"
        src="https://qncweb.ktvsky.com/20231205/vadd/e24e8b0fd8b2ba5f24df9b86ba0bc9ef.png"
      />
      <form @submit.prevent="handleFormSubmit" action="javascript:return true">
        <input
          type="text"
          :placeholder="isShowSearchTip ? '' : placeholder"
          v-model="keyword"
          class="pure-search-bar-tool-input"
          :disabled="!isSearch"
          v-focus
          ref="searchInputDom"
          @keydown="handleSearchKeydown($event)"
          @keyup="handleSearchKeyup($event)"
        />
        <div v-if="isShowSearchTip" class="tip" @click="handleFocusInput">
          限时<span>0.2元/天</span>全曲库任意唱
        </div>
      </form>
    </div>
    <div class="search-btn" @click="handleSearch">搜索</div>
    <div
      v-show="!isSearch"
      class="pure-search-bar-tool-mask"
      @click="handleToSearch"
    ></div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import useSongItem from "@/composables/useSongItem";
import useLoginValid from "@/composables/useLoginValid";
import useVip from "@/composables/useVip";
import { sendLog } from "@/directives/v-log/log";
export default {
  name: "PureSearch",
  components: {},
  props: {
    placeholder: String,
    isImmediately: Boolean,
    isSearch: Boolean,
    customStyle: {
      type: Object,
      default: () => ({}),
    },
    defaultTab: {
      type: String,
      default: "song",
    },
  },
  methods: {
    handleFocusInput() {
      const inputDom = this.$refs.searchInputDom;
      inputDom.focus();
    },
    handleClearInput() {
      const inputDom = this.$refs.searchInputDom;
      inputDom.value = "";
      this.keyword = "";
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { orderSong } = useSongItem();
    const { showLoginQrcode, isLogin } = useLoginValid();
    const { showVipQrcode } = useVip();
    const timeData = ref({});
    // 行为埋点 str1 event_name(二维码展示、支付码展示)
    const logEvents = {
      home: ["首页", 30118, 30119],
      search: ["搜索页/结果页", 30122, 30123],
      singing: ["唱过的歌页", 30126, 30127],
      singer: ["歌星列表页", 30130, 30131],
      songList: ["歌星详情页", 30134, 30135],
      mine: ["我的页", "", 30138],
      profile: ["会员中心页", "", 30140],
    };

    // 搜索页和歌星页的来源埋点
    const frObj = {
      search: 1764,
      singer: 1766,
      songList: 1817,
    };
    const route_page = computed(() => route.name);

    // const videoPaused = computed(() => store.state.videoPaused)
    let videoPlayerHistory = computed(() => store.state.videoPlayerHistory);
    const orderedSongIdMap = computed(() => store.state.orderedSongIdMap);
    const initControlPlay = computed(() => store.state.videoInitAutoPlay);
    const orderedList = computed(() => store.state.orderedList);
    const orderedSongNum = computed(() => store.state.orderedList.length);

    const isSingStatus = computed(() => store.state.isSingStatus);
    const isSinging = computed(
      () =>
        isSingStatus.value && orderedSongNum.value && !store.state.videoPaused
    );
    const isShowSearchTip = computed(
      () => !keyword.value && !isInputNotEmpty.value && route.name === "search"
    );
    const isVip = computed(() => !!store.state.vipInfo.end_time);
    const freeVipNumber = computed(() => Number(store.state.freeVipNumber));
    const userType = computed(() => store.state.userInfo.userType);
    // 显示歌名 - 歌手 （未播放：显示第一首 正在播放：显示当前这一首以及下一首）
    // const currentOrderSong = computed(() => {
    //   if (!orderedSongNum.value) return ''
    //   if (!isSingStatus.value) {
    //     return `${orderedList.value[0].music_name} - ${orderedList.value[0].singer}`
    //   }
    //   if (orderedSongNum.value > 1) {
    //     return `正在播放:${orderedList.value[0].music_name} - ${orderedList.value[0].singer}   下一首:${orderedList.value[1].music_name} - ${orderedList.value[1].singer}`
    //   }
    //   return `正在播放:${orderedList.value[0].music_name} - ${orderedList.value[0].singer}   下一首:没有歌啦，快去点歌吧~`
    // })

    // 跑马灯内容宽度
    // const scrollTextWidth = computed(() => currentOrderSong.value.length * 24)
    let keyword = ref("");

    const isInputNotEmpty = ref(false);

    // const handleTimeFinish = ()=>{
    //   eventBus.emit('handle-time-finish')
    // }
    const handleBack = () => {
      router.back();
    };

    const handleToSearch = () => {
      sendLog({
        event_type: "10000~50000",
        event_name: 10003,
        event_data: {
          str1: "首页",
          str2: "搜索栏",
          str3: "进入搜索",
          str4: "click",
        },
      });
      router.push({
        name: "search",
        query: { defaultTab: props.defaultTab },
      });
    };

    const handleClearKeyword = () => {
      keyword.value = "";
    };

    const handleChangeKeyword = (e) => {
      keyword.value = e;
    };

    const handleFormSubmit = () => {
      return false;
    };

    const handleSearch = () => {
      emit("search", keyword.value);
    };

    // watch(keyword, (newVal) => {
    //   // console.log('newVal', newVal)
    //   if (!newVal) {
    //     emit('search', '')
    //   }
    // })

    const handleSearchKeydown = (e) => {
      if (e.keyCode == 13) {
        handleSearch();
      }
    };
    const handleSearchKeyup = (e) => {
      isInputNotEmpty.value = !!e.target.value;
    };

    const resetValue = () => {
      keyword.value = "";
      isInputNotEmpty.value = false;
    };

    onMounted(() => {
      sendLog({
        event_type: "10000~50000",
        event_name: 30223,
        event_data: {
          str1: "通用",
          str2: "权益顶部运营位",
          str3: "展示",
          str4: "show",
          str5: isLogin.value ? "已登录" : "未登录",
          str7: userType.value,
        },
      });
    });

    return {
      keyword,
      route_page,
      isSinging,
      handleBack,
      handleToSearch,
      handleSearch,
      handleChangeKeyword,
      handleClearKeyword,
      handleSearchKeydown,
      handleFormSubmit,
      isShowSearchTip,
      handleSearchKeyup,
      resetValue,
      isLogin,
      isVip,
    };
  },
};
</script>

<style lang="stylus" scoped>
.pure-search-bar
  display flex
  align-items center
  justify-content space-between
  background rgba(0, 0, 0, 1)
  position fixed
  top 0
  left 0
  width 100vw
  z-index 6
  padding 0px 60px
  height 92px
  &-tool
    position relative
    width 586px !important
    height 92px !important
    // border 2px solid rgba(255, 255, 255, 0.20)
    border none
    background rgba(255,255,255,0.08)
    // border-radius 24px
    border-radius 0px
    display flex
    align-items center
    @media screen and (max-width 1200px)
      width 835px !important
      height 72px
    &-mask
      position absolute
      top 0
      right 0
      left 0
      bottom 0
      width 100%
      height 100%
    &-icon
      width 27px
      height 27px
      margin 0 18px 0 31px
    form
      width calc(100% - 90px)
      position relative
      .tip
        width 100%
        height 100%
        position absolute
        top 0
        left 3px
        color #FFFFFF66
        span
          color #DBAE6A
          margin 0 5px
    &-input
      display flex
      align-items center
      width 100% !important
      height 40px
      font-size 28px
      font-weight 500
      color rgba(255, 255, 255, .8)
      @media screen and (max-width 1200px)
        font-size 22px
  .search-input
    width 520px
    margin-left 0px
    margin-right 40px
    @media screen and (max-width 1200px)
      width 270px
      height 72px
      margin-right 20px
  .search-btn
    display flex
    justify-content center
    align-items center
    width 200px !important
    height 92px
    border-radius 4px
    background #DBAE6A
    backdrop-filter blur(100px)
    color rgba(0, 0, 0, 0.8)
    font-size 28px
    @media screen and (max-width 1200px)
      width 200px !important
      height 92px
      font-size 22px
</style>
